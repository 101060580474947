







import { Component, Vue } from "vue-property-decorator";
import LoginLayout from "../../components/commons/layouts/LoginLayout.vue";
import TerminalNewPassword from "../../components/auth/TerminalNewPassword.vue";

@Component({
  components: {
    LoginLayout,
    TerminalNewPassword,
  },
})
export default class NewPasswordPage extends Vue {}
