import { render, staticRenderFns } from "./TerminalNewPassword.vue?vue&type=template&id=2501d760&scoped=true&"
import script from "./TerminalNewPassword.vue?vue&type=script&lang=ts&"
export * from "./TerminalNewPassword.vue?vue&type=script&lang=ts&"
import style0 from "./TerminalNewPassword.vue?vue&type=style&index=0&id=2501d760&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2501d760",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VForm})
